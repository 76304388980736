.master-slider {
  visibility: hidden;
  position: relative;
  -moz-transform: translate3d(0, 0, 0);
}

.master-slider a {
  -o-transition: none;
  transition: none;
}

.master-slider, .master-slider * {
  box-sizing: content-box;
}

.master-slider.ms-fullheight {
  height: 100%;
}

.ms-layout-partialview .ms-view {
  background-color: #0000;
  overflow: visible;
}

.ms-layout-partialview .ms-container {
  overflow: hidden;
}

.master-slider img, .ms-slide-bgvideocont video {
  max-width: none;
}

.ms-ie img {
  border: none;
}

.ms-ie8 * {
  opacity: inherit;
  filter: inherit;
}

.ms-ie7 {
  zoom: 1;
}

.ms-moz .ms-slide-bgcont > img {
  transform: translateX(0);
}

.ms-loading-container {
  width: 100%;
  position: absolute;
  top: 0;
}

.ms-slide-auto-height {
  display: table;
}

.ms-loading-container .ms-loading, .ms-slide .ms-slide-loading {
  width: 36px;
  height: 36px;
  opacity: .9;
  background: #fff url("loading-2.3360bd30.gif") center no-repeat;
  border-radius: 60px;
  margin: -18px;
  position: absolute;
  top: 50%;
  left: 50%;
  box-shadow: 0 0 3px #0003;
}

.ms-no-swipe, .ms-def-cursor {
  cursor: auto !important;
}

.ms-grab-cursor {
  cursor: url("grab.066f0bb7.png"), move;
}

.ms-grabbing-cursor {
  cursor: url("grabbing.42a6b8ce.png"), move;
}

.ms-ie .ms-grab-cursor, .ms-ie .ms-grabbing-cursor {
  cursor: move;
}

.ms-view {
  perspective: 2000px;
  -ms-perspective: 2000px;
  background: #000;
  margin: 0 auto;
  position: relative;
  overflow: hidden;
}

.ms-view .ms-slide-container {
  position: relative;
}

.ms-slide {
  height: 100%;
  position: absolute;
  transform: translateZ(0);
}

.ms-wk .ms-slide {
  transform-style: preserve-3d;
}

.ms-slide .ms-slide-bgcont, .ms-slide .ms-slide-bgvideocont {
  width: 100%;
  position: absolute;
  overflow: hidden;
}

.ms-slide .ms-slide-link {
  width: 100%;
  height: 100%;
  z-index: 110;
  display: block;
  position: absolute;
  top: 0;
}

.ms-ie .ms-slide-link {
  background: url("data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBR‌​AA7");
}

.ms-slide .ms-slide-bgvideocont {
  z-index: 105;
  height: 100%;
}

.ms-wk .ms-slide .ms-slide-bgvideocont, .ms-wk .ms-slide-bgvideocont > video {
  transform: translateZ(.3px);
}

.ms-slide .ms-slide-bgvideo {
  position: absolute;
  top: 0;
}

.ms-inner-controls-cont, .ms-container {
  margin: 0 auto;
  position: relative;
}

.ms-slide .ms-slide-bgcont img, .ms-container, .ms-inner-controls-cont {
  -webkit-user-select: none;
  user-select: none;
}

.ms-slide .ms-layer, .ms-fixed-layers .ms-layer {
  pointer-events: auto;
  position: absolute;
}

.ms-slide .ms-slide-layers, .ms-fixed-layers {
  width: 100%;
  height: 100%;
  z-index: 109;
  pointer-events: none;
  position: absolute;
  top: 0;
  overflow: hidden;
}

.ms-slide .ms-slide-video {
  z-index: 111;
  background: #000;
  border: none;
  margin: 0;
  padding: 0;
  position: absolute;
  top: 0;
}

.ms-parallax-layer {
  width: 100%;
  position: absolute;
  top: 0;
}

.ms-scroll-parallax-cont {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
}

.ms-wk .ms-scroll-parallax-cont {
  transform: translateZ(.4px);
}

.ms-action-layer {
  cursor: pointer;
}

.ms-btn-container {
  position: absolute;
}

.ms-btn {
  color: #000;
  cursor: pointer;
  white-space: nowrap;
  padding: 8px 15px;
  text-decoration: none;
  display: inline-block;
  position: relative;
}

.ms-default-btn {
  color: #fff;
  background: #0074a2;
  border-radius: 5px;
}

.ms-default-btn:hover {
  background-color: #0098d5;
}

.ms-default-btn:active {
  top: 1px;
}

.ms-btn-s {
  padding: 14px 17px;
  font-size: 80%;
  line-height: 0;
}

.ms-btn-m {
  padding: 12px 40px;
}

.ms-btn-n {
  padding: 8px 25px;
  font-size: 95%;
}

.ms-btn-l {
  padding: 17px 50px;
  font-size: 120%;
}

.ms-btn-round {
  border-radius: 5px;
}

.ms-btn-circle {
  border-radius: 100px;
}

.ms-btn-outline-round {
  border-radius: 5px;
}

.ms-btn-outline-circle {
  border-radius: 100px;
}

.ms-layer.video-box {
  background: #000;
}

.ms-layer.video-box iframe {
  z-index: 111;
  border: none;
  margin: 0;
  padding: 0;
  position: absolute;
}

.ms-slide .ms-slide-vpbtn, .ms-slide .ms-video-btn {
  cursor: pointer;
  z-index: 112;
  position: absolute;
}

.ms-slide .ms-slide-vcbtn {
  cursor: pointer;
  z-index: 113;
  position: absolute;
}

.ms-slide-vcbtn-mobile {
  width: 100%;
  height: 28px;
  color: #fff;
  text-align: center;
  cursor: pointer;
  z-index: 113;
  background-color: #000;
  position: relative;
  bottom: 0;
  left: 0;
}

.ms-slide-vcbtn-mobile .ms-vcbtn-txt {
  text-transform: uppercase;
  height: 15px;
  color: #dbdbdb;
  background: url("video-close-btn.f41d96fa.png") 0 3px no-repeat;
  margin-top: 7px;
  padding-left: 15px;
  font-family: sans-serif;
  font-size: .75em;
  display: inline-block;
}

.ms-wk .ms-slide .ms-slide-vcbtn, .ms-wk .ms-slide .ms-slide-layers, .ms-wk .ms-slide .ms-slide-vpbtn, .ms-wk .ms-slide .ms-slide-video, .ms-wk .ms-slide-link {
  transform: translateZ(.44px);
}

.ms-video-img {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.ms-mask-frame {
  float: left;
  position: absolute;
  overflow: hidden;
}

.ms-thumb-list {
  position: absolute;
  overflow: hidden;
}

.ms-thumb-list.ms-dir-h {
  width: 100%;
}

.ms-thumb-list.ms-dir-v {
  height: 100%;
  top: 0;
}

.ms-thumbs-cont {
  position: relative;
}

.ms-thumb-frame img {
  width: 100%;
  height: auto;
}

.ms-thumb-frame {
  cursor: pointer;
  float: left;
  opacity: .5;
  -o-transition: opacity .3s;
  transition: opacity .3s;
  overflow: hidden;
}

.ms-thumb-frame-selected {
  opacity: 1;
}

.ms-tabs .ms-thumb-frame {
  text-shadow: 0 1px 1px #fff;
  color: #222;
  opacity: 1;
  -o-transition: all .3s ease-out;
  background-color: #f6f6f6;
  border: 1px solid #fff;
  border-width: 0 1px 1px 0;
  transition: all .3s ease-out;
  position: relative;
  overflow: visible;
}

.ms-tabs.ms-dir-h .ms-thumb-frame {
  margin-top: 12px;
}

.ms-tabs.ms-dir-v .ms-thumb-frame {
  margin-left: 12px;
}

.ms-tabs.ms-dir-v.ms-align-left .ms-thumb-frame {
  margin-left: 0;
}

.ms-tabs.ms-dir-h.ms-align-top {
  z-index: 140;
}

.ms-tabs.ms-dir-h.ms-align-top .ms-thumb-frame {
  margin-top: -12px;
}

.ms-tabs .ms-thumb {
  padding: 20px;
}

.ms-tabs .ms-thumb-frame-selected {
  opacity: 1;
  background: #ededed;
}

.ms-tabs .ms-thumb-frame .ms-thumb-ol {
  opacity: 0;
  -o-transition: all .3s ease-out;
  transition: all .3s ease-out;
  position: absolute;
}

.ms-tabs.ms-dir-h .ms-thumb-frame .ms-thumb-ol {
  border-bottom: 12px solid #f6f6f6;
  border-left: 12px solid #0000;
  border-right: 12px solid #0000;
  margin-left: -12px;
  top: 0;
  left: 50%;
}

.ms-tabs.ms-dir-h.ms-align-bottom .ms-thumb-frame-selected .ms-thumb-ol {
  opacity: 1;
  border-bottom-color: #ededed;
  top: -12px;
}

.ms-tabs.ms-dir-h.ms-align-top .ms-thumb-frame .ms-thumb-ol {
  border-top: 12px solid #f6f6f6;
  border-bottom: 0;
  top: auto;
  bottom: 0;
}

.ms-tabs.ms-dir-h.ms-align-top .ms-thumb-frame-selected .ms-thumb-ol {
  opacity: 1;
  border-top-color: #ededed;
  bottom: -12px;
}

.ms-tabs.ms-dir-v .ms-thumb-frame .ms-thumb-ol {
  border-top: 12px solid #0000;
  border-bottom: 12px solid #0000;
  border-right: 12px solid #ededed;
  margin-top: -12px;
  top: 50%;
  left: 0;
}

.ms-tabs.ms-dir-v.ms-align-right .ms-thumb-frame-selected .ms-thumb-ol {
  opacity: 1;
  border-right-color: #ededed;
  left: -12px;
}

.ms-tabs.ms-dir-v.ms-align-left .ms-thumb-frame .ms-thumb-ol {
  border-left: 12px solid #ededed;
  border-right: 0;
  left: auto;
  right: 0;
}

.ms-tabs.ms-dir-v.ms-align-left .ms-thumb-frame-selected .ms-thumb-ol {
  opacity: 1;
  border-left-color: #ededed;
  right: -12px;
}

.ms-thumb-list.ms-dir-h .ms-thumblist-fwd, .ms-thumb-list.ms-dir-h .ms-thumblist-bwd {
  height: 100%;
  width: 20px;
  cursor: pointer;
  text-align: center;
  color: #fff;
  background: #80808080;
  position: absolute;
  left: 0;
}

.ms-thumb-list.ms-dir-h .ms-thumblist-bwd {
  left: auto;
  right: 0;
}

.ms-thumb-list.ms-dir-h .ms-thumblist-fwd:after, .ms-thumb-list.ms-dir-h .ms-thumblist-bwd:after {
  content: close-quote;
  width: 6px;
  height: 1px;
  border: 5px solid #0000;
  border-left-width: 0;
  border-right-color: #fff;
  display: block;
  position: relative;
  top: 43%;
}

.ms-thumb-list.ms-dir-h .ms-thumblist-bwd:after {
  border-width: 5px 0 5px 5px;
  border-left-color: #fff;
  left: 7px;
}

.ms-thumb-list.ms-dir-v .ms-thumblist-fwd, .ms-thumb-list.ms-dir-v .ms-thumblist-bwd {
  width: 100%;
  height: 20px;
  cursor: pointer;
  text-align: center;
  color: #fff;
  background: #80808080;
  position: absolute;
  top: 0;
}

.ms-thumb-list.ms-dir-v .ms-thumblist-bwd {
  top: auto;
  bottom: 0;
}

.ms-thumb-list.ms-dir-v .ms-thumblist-fwd:after, .ms-thumb-list.ms-dir-v .ms-thumblist-bwd:after {
  content: close-quote;
  height: 1px;
  width: 0;
  border: 5px solid #0000;
  border-top-width: 0;
  border-bottom-color: #fff;
  display: block;
  position: relative;
  top: 5px;
  left: 43%;
}

.ms-thumb-list.ms-dir-v .ms-thumblist-bwd:after {
  border-width: 5px 5px 0;
  border-top-color: #fff;
  top: 7px;
}

.ms-bullet {
  cursor: pointer;
  float: left;
}

.ms-bullets.ms-dir-h {
  width: 100px;
  position: absolute;
  bottom: 20px;
  right: 50%;
}

.ms-bullets.ms-dir-v {
  position: absolute;
  top: 50%;
}

.ms-bullets.ms-dir-v .ms-bullet {
  float: none;
}

.ms-bullets.ms-dir-h .ms-bullets-count {
  position: relative;
  right: -50%;
}

.ms-sbar {
  position: absolute;
}

.ms-sbar.ms-dir-h {
  top: 5px;
  left: 10px;
  right: 10px;
}

.ms-sbar.ms-dir-v {
  top: 10px;
  bottom: 10px;
  right: 5px;
}

.ms-sbar .ms-bar {
  height: 4px;
  background: #333;
  border-radius: 4px;
}

.ms-sbar.ms-dir-v .ms-bar {
  width: 4px;
}

.ms-sbar .ms-bar {
  -o-transition: opacity .3s;
  transition: opacity .3s;
  position: relative;
}

.ms-timerbar {
  width: 100%;
  position: absolute;
  bottom: 0;
}

.ms-ctimer {
  cursor: pointer;
  position: absolute;
  top: 30px;
  left: 30px;
}

.ms-time-bar {
  -o-transition: width .12s linear;
  transition: width .12s linear;
}

.ms-ie7 .ms-tooltip-arrow {
  height: 0;
}

.ms-slide-info {
  position: absolute;
}

.ms-slide-info.ms-dir-v {
  top: 0;
}

.ms-slide-info.ms-dir-h {
  width: 100%;
}

@keyframes point-anim {
  0% {
    opacity: 1;
    transform: scale(.5);
  }

  100% {
    transform: scale(.5);
    opacity: 0;
    -moz-transform: scale(1.5);
    -ms-transform: scale(1.5);
    -o-transform: scale(1.5);
  }
}

@-webkit-keyframes point-anim {
  0% {
    opacity: 1;
    -webkit-transform: scale(.5);
  }

  100% {
    opacity: 0;
    -webkit-transform: scale(1.5);
  }
}

.ms-android .ms-tooltip-point .ms-point-border {
  animation: none;
}

/*# sourceMappingURL=index.71931cd6.css.map */
